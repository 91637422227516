/* eslint-disable namespace-import-matching/namespace-import-matching */
import type { ToasterToast } from "./primitives/toast.tsx"

import * as Icon from "@iyk/icons"
import * as React from "react"
import * as ToastPrimitive from "./primitives/toast.tsx"

import { Link } from "@remix-run/react"

import { cva, type VariantProps } from "./cva.ts"

//#region Toaster

function Toaster(
  props: React.ComponentPropsWithoutRef<typeof ToastPrimitive.Provider>,
) {
  const { toasts } = ToastPrimitive.useToast()

  return (
    <ToastPrimitive.Provider swipeDirection="down" {...props}>
      {toasts.map((props) => (
        <ToastElement key={`toast-${props.id}`} {...props} />
      ))}
      <ToastViewport />
    </ToastPrimitive.Provider>
  )
}

function ToastElement(props: ToasterToast) {
  const { id, title, description, action, hideCloseButton, state } = props
  const [isActionClicked, setIsActionClicked] = React.useState(false)

  return (
    <ToastRoot key={id} {...props}>
      {state && <ToastIcon state={state} className={classForToastIcon()} />}
      <div>
        <div className="grid gap-1">
          {title && <ToastTitle>{title}</ToastTitle>}
          {description && <ToastDescription>{description}</ToastDescription>}
        </div>
        {action && (
          <div className="mt-3">
            {action.href ? (
              <Link to={action.href}>
                <ToastAction altText={action.label} onClick={action.onClick}>
                  {action.label}
                </ToastAction>
              </Link>
            ) : (
              <ToastAction
                altText={action.label}
                onClick={(e) => {
                  if (action.persistOnClick) {
                    e.preventDefault()
                  }

                  action.onClick?.()
                  setIsActionClicked(true)
                }}
              >
                {isActionClicked && action.clickedLabel
                  ? action.clickedLabel
                  : action.label}
              </ToastAction>
            )}
          </div>
        )}
      </div>
      {hideCloseButton ? null : <ToastClose />}
    </ToastRoot>
  )
}

//#endregion

//#region Toast

const ToastRoot = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitive.Root
      ref={ref}
      className={toastVariants({ variant, className })}
      {...props}
    />
  )
})

const toastVariants = cva(
  [
    "group pointer-events-auto relative w-full flex overflow-hidden rounded-md border p-3 space-x-3 transition-all shrink-0",
    "data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[swipe=move]:transition-none",
    "data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full",
  ],
  {
    variants: {
      variant: {
        default: [
          "bg-gray-12 text-gray-1",
          "dark:bg-gray-2-dark dark:text-gray-12-dark",
        ],
        destructive: "destructive border-tomato-4 bg-tomato-3 text-tomato-11",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

//#endregion

//#region ToastViewport

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.Viewport
    ref={ref}
    className={classForToastViewport({
      className,
    })}
    {...props}
  />
))

const classForToastViewport = cva(
  "fixed bottom-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 gap-y-2 sm:left-0 sm:flex-col md:max-w-[420px]",
)

//#endregion

//#region ToastAction

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.Action
    ref={ref}
    className={classForToastAction({ className })}
    {...props}
  />
))

const classForToastAction = cva([
  "shrink-0 px-1.5 py-1 text-xs text-gray-1-light rounded-md border border-gray-11-light cursor-pointer outline-none transition-colors disabled:pointer-events-none disabled:opacity-50",
  "group-[.destructive]:border-tomato-11 group-[.destructive]:text-tomato-11",
])

//#endregion

//#region ToastClose

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.Close
    ref={ref}
    className={classForToastClose({ className })}
    toast-close=""
    {...props}
  >
    <Icon.Cross />
  </ToastPrimitive.Close>
))

const classForToastClose = cva(
  "absolute right-1 top-1 p-1 text-gray-8-light transition-opacity hover:text-gray-1-light focus:opacity-100 focus:outline-none group-[.destructive]:text-tomato-8-light hover:group-[.destructive]:text-tomato-9-light",
)

//#endregion

//#region ToastTitle

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.Title
    ref={ref}
    className={classForToastTitle({ className })}
    {...props}
  />
))

const classForToastTitle = cva(["text-sm"])

//#endregion

//#region ToastDescription

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.Description
    ref={ref}
    className={classForToastDescription({ className })}
    {...props}
  />
))

const classForToastDescription = cva([
  "text-sm",
  "text-gray-8",
  "dark:text-gray-11-dark",
  "group-[.destructive]:text-tomato-11",
])

//#endregion

//#region ToastIcon

const ToastIcon = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.ToastIcon>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.ToastIcon>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.ToastIcon
    ref={ref}
    className={classForToastIcon({ className })}
    {...props}
  />
))

const classForToastIcon = cva(
  "shrink-0 [&>svg]:text-gray-1-light [&>svg]:group-[.destructive]:text-tomato-11",
)

//#endregion

//#region Exports

type ToastVariants = VariantProps<typeof toastVariants>

const toast = ToastPrimitive.toast<ToastVariants>
const useToast = ToastPrimitive.useToast

export { toast, Toaster, useToast }
